import React from 'react';

const Mail = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="88"
    height="60"
    viewBox="0 0 88 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M85.8372 60H2.32238C1.88361 60 1.46272 59.8256 1.15246 59.5154C0.842196 59.2053 0.667969 58.7848 0.667969 58.3461V1.65381C0.667969 1.21519 0.842196 0.794665 1.15246 0.484515C1.46272 0.174365 1.88361 0 2.32238 0H85.8372C86.276 0 86.6968 0.174365 87.0071 0.484515C87.3174 0.794665 87.4916 1.21519 87.4916 1.65381V58.5448C87.4428 58.9482 87.2472 59.3197 86.9421 59.5881C86.6369 59.8565 86.2436 60.0029 85.8372 60ZM3.9768 56.6923H84.1828V3.30762H3.9768V56.6923Z"
      fill="#FCFCFD"
    />
    <path
      d="M44.0785 43.1982C43.6422 43.1745 43.225 43.0119 42.8874 42.7346L1.19601 3.04315C1.00312 2.90613 0.841942 2.72935 0.723234 2.52472C0.604526 2.3201 0.531119 2.0923 0.50795 1.8569C0.484781 1.62149 0.512394 1.38383 0.588934 1.16C0.665474 0.936177 0.78917 0.731982 0.951645 0.560012C1.11412 0.388043 1.31168 0.252684 1.53085 0.163517C1.75002 0.0743507 1.98571 0.0327067 2.22214 0.0423886C2.45857 0.0520704 2.69017 0.112449 2.90132 0.219237C3.11246 0.326025 3.29833 0.477083 3.44619 0.661761L44.0785 39.427L84.6448 0.661761C84.7991 0.501701 84.9842 0.374028 85.1888 0.287069C85.3935 0.20011 85.6135 0.155442 85.8359 0.155442C86.0583 0.155442 86.2785 0.20011 86.4832 0.287069C86.6878 0.374028 86.8727 0.501701 87.027 0.661761C87.3302 0.970906 87.5 1.38686 87.5 1.81975C87.5 2.25264 87.3302 2.66779 87.027 2.97694L45.2696 42.6684C44.9498 42.9832 44.5266 43.1718 44.0785 43.1982Z"
      fill="#FCFCFD"
    />
    <path
      d="M2.32149 60.0009C2.09744 59.9979 1.87636 59.9497 1.6716 59.8587C1.46684 59.7678 1.28271 59.6362 1.13036 59.4719C0.972096 59.3235 0.846002 59.1437 0.759773 58.9446C0.673544 58.7455 0.628906 58.5309 0.628906 58.3139C0.628906 58.097 0.673544 57.8824 0.759773 57.6833C0.846002 57.4842 0.972096 57.3052 1.13036 57.1568L30.7773 28.7771C31.0866 28.4741 31.5025 28.3047 31.9355 28.3047C32.3686 28.3047 32.7843 28.4741 33.0936 28.7771C33.2518 28.9256 33.3779 29.1053 33.4641 29.3044C33.5504 29.5035 33.595 29.7181 33.595 29.9351C33.595 30.1521 33.5504 30.3666 33.4641 30.5658C33.3779 30.7649 33.2518 30.9438 33.0936 31.0923L3.38034 59.4719C3.09478 59.7593 2.72282 59.9449 2.32149 60.0009Z"
      fill="#FCFCFD"
    />
    <path
      d="M85.8351 59.9988C85.3968 59.9851 84.9764 59.822 84.644 59.5361L54.9968 31.1564C54.8039 31.0194 54.6427 30.8426 54.524 30.638C54.4053 30.4334 54.3319 30.2056 54.3087 29.9702C54.2856 29.7348 54.3132 29.4971 54.3897 29.2733C54.4663 29.0495 54.59 28.8453 54.7524 28.6733C54.9149 28.5013 55.1125 28.366 55.3316 28.2768C55.5508 28.1876 55.7865 28.146 56.0229 28.1557C56.2594 28.1654 56.491 28.2257 56.7021 28.3325C56.9132 28.4393 57.0989 28.5904 57.2468 28.775L86.9602 57.1547C87.2633 57.4639 87.4332 57.879 87.4332 58.3119C87.4332 58.7448 87.2633 59.1608 86.9602 59.4699C86.6607 59.7752 86.2614 59.9627 85.8351 59.9988Z"
      fill="#FCFCFD"
    />
  </svg>
);

export default Mail;
